/* eslint max-len:0 */
const Czech = {
    _language: 'czech',
    _language_short: 'cs',
    agentWelkomMessage: 'Vítejte. Zeptejte se své otázky zde.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Nepodařilo se připojit. Můžete {retry}',
    couldNotConnectInner: 'zkuste se připojit znovu nyní',
    couldNotConnectWithRetry: 'Nepodařilo se připojit. Budeme pokračovat v opakování, nebo můžete {retry}.',
    couldNotConnectWithRetryInner: 'zkuste nyní',
    headerText: 'Jak vám můžeme pomoci?',
    imageClickToReload: 'Kliknutím načtěte obrázek znovu.',
    imageClickToView: 'Kliknutím zobrazte obrázek {size}.',
    imagePreviewNotAvailable: 'Náhled není k dispozici.',
    inputPlaceholder: 'Sem napište a stiskněte <enter> pro odeslání.',
    inputPlaceholderBlocked: 'Dokončete formulář níže...',
    introAppText: 'Pošlete svou zprávu níže nebo z vašeho oblíbeného kanálu.',
    introductionText: 'Zeptejte se své otázky zde.',
    actionPaymentCompleted: 'Platba byla přijata.',
    actionPaymentError: 'Při zpracování platby došlo k chybě. <br> Prosím, zkuste to znovu nebo použijte jinou kartu.',
    actionPostbackError: 'Při zpracování vaší akce došlo k chybě. Prosím, zkuste to znovu.',
    clickToRetry: 'Zpráva nebyla doručena. Prosím, zkuste to znovu.',
    clickToRetryForm: 'Formulář nebyl odeslán. Klepnutím kdekoli na formulář zkuste to znovu.',
    connectNotificationText: 'Čekejte na odpověď nebo si vyberte, ve které aplikaci chcete pokračovat.',
    connectNotificationSingleText: 'Čekejte na odpověď nebo si vyberte, ve které aplikaci chcete pokračovat.',
    connectNotificationSingleButtonText: 'Zapněte oznámení od <name>',
    connectNotificationOthersText: 'další',
    emailChangeAddress: 'Změnit mou e-mailovou adresu',
    emailDescription: 'Abyste byli informováni e-mailem o odpovědi, zadejte svou e-mailovou adresu.',
    emailFieldLabel: 'Váš e-mail',
    emailFieldPlaceholder: 'Vaše e-mailová adresa',
    emailFormButton: 'Pokračovat',
    fetchHistory: 'Načíst více',
    fetchingHistory: 'Načítání historie...',
    frontendEmailChannelDescription: 'Pošlete nám e-mail a odpovíme vám co nejdříve.',
    invalidFileError: 'Podporovány jsou pouze obrázky. Vyberte soubor s podporovaným formátem (jpg, jpeg, png, gif nebo bmp).',
    lineChannelDescription: 'Pro komunikaci s námi skenujte QR kód pomocí aplikace LINE.',
    locationNotSupported: 'Váš prohlížeč nepodporuje služby určení polohy nebo jsou vypnuty. Prosím, napište svou polohu.',
    locationSecurityRestriction: 'Tato webová stránka nemá přístup k vaší poloze. Prosím, napište svou polohu.',
    locationSendingFailed: 'Poloha nemohla být odeslána',
    locationServicesDenied: 'Tato webová stránka nemá přístup k vaší poloze. Povolte přístup ve vašich nastaveních nebo napište svou polohu.',
    messageError: 'Při odesílání vaší zprávy došlo k chybě. Prosím, zkuste to znovu.',
    messageIndicatorTitlePlural: '({count}) Nové zprávy',
    messageIndicatorTitleSingular: '({count}) Nová zpráva',
    messageRelativeTimeDay: 'Před {value} dny',
    messageRelativeTimeHour: 'Před {value} hodinami',
    messageRelativeTimeJustNow: 'Právě teď',
    messageRelativeTimeMinute: 'Před {value} minutami',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'Odesílání...',
    messageDelivered: 'Doručeno',
    messengerChannelDescription: 'Pro pokračování pomocí Facebook Messengeru připojte svůj účet na Facebooku.',
    whatsappChannelDescriptionDesktop: 'Začněte nebo pokračujte ve svém rozhovoru prostřednictvím WhatsApp. Klikněte na odkaz níže.',
    whatsappChannelDescriptionMobile: 'Začněte nebo pokračujte ve svém rozhovoru prostřednictvím WhatsApp. Klikněte na odkaz níže.',
    whatsappLinkingError: 'Při připojování došlo k chybě. Prosím, zkuste to znovu.',
    notificationSettingsChannelsDescription: 'Můžete si také povídat z vaší oblíbené aplikace nebo služby.',
    notificationSettingsChannelsTitle: 'Další aplikace',
    notificationSettingsConnected: 'Připojeno',
    notificationSettingsConnectedAs: 'Připojeno jako {username}',
    prechatCaptureGreetingText: 'Ahoj 👋\nRádi bychom o vás věděli trochu víc, abychom začali:',
    prechatCaptureNameLabel: 'Vaše jméno',
    prechatCaptureNamePlaceholder: 'Zadejte své jméno...',
    prechatCaptureEmailLabel: 'E-mail',
    prechatCaptureEmailPlaceholder: 'name@company.com',
    prechatCaptureConfirmationText: 'Děkujeme za to! Jak vám můžeme pomoci?',
    prechatCaptureMailgunLinkingConfirmation: 'Budete informováni zde a e-mailem na {email}, až vám odpovíme. ',
    sendButtonText: 'Odeslat',
    settingsHeaderText: 'Nastavení',
    smsBadRequestError: 'Nepodařilo se připojit k tomuto číslu. Zkuste to znovu nebo použijte jiné číslo. ',
    smsCancel: 'Zrušit',
    smsChangeNumber: 'Změnit mé číslo',
    smsChannelDescription: 'Pro pokračování prostřednictvím SMS připojte své SMS číslo.',
    smsChannelPendingDescription: 'Zkontrolujte vaše zprávy na {number} pro potvrzení vašeho telefonního čísla.',
    smsContinue: 'Pokračovat',
    smsInvalidNumberError: 'Vaše telefonní číslo není platné. Zkuste to znovu.',
    smsLinkCancelled: 'Připojení k {appUserNumber} bylo zrušeno.',
    smsLinkPending: 'Čeká',
    smsPingChannelError: 'Při odesílání zprávy na vaše číslo došlo k chybě.',
    smsSendText: 'Pošlete mi text',
    smsStartTexting: 'Začněte psát SMS',
    smsTooManyRequestsError: 'Požadavek na připojení k tomuto číslu byl nedávno podán. Prosím, zkuste to znovu za {minutes} minut.',
    smsTooManyRequestsOneMinuteError: 'Požadavek na připojení k tomuto číslu byl nedávno podán. Prosím, zkuste to znovu za 1 minutu.',
    smsUnhandledError: 'Něco se pokazilo. Prosím, zkuste to znovu.',
    tapToRetry: 'Zpráva nebyla doručena. Stiskněte enter pro opětovné odeslání.',
    tapToRetryForm: 'Formulář nebyl odeslán. Klepnutím na formulář zkuste to znovu.',
    telegramChannelDescription: 'Pro pokračování prostřednictvím Telegramu připojte svůj účet na Telegramu.',
    unsupportedMessageType: 'Nepodporovaný typ zprávy.',
    unsupportedActionType: 'Nepodporovaný typ akce.',
    linkError: 'Při pokusu o vytvoření odkazu pro tento kanál došlo k chybě. Prosím, zkuste to znovu.',
    viberChannelDescription: 'Pro pokračování prostřednictvím Viberu připojte svůj Viber účet.',
    viberChannelDescriptionMobile: 'Pro pokračování prostřednictvím Viberu připojte svůj Viber účet a budete upozorněni na odpověď. Pro začátek nainstalujte aplikaci Viber a klepněte na Připojit.',
    viberQRCodeError: 'Při získávání vášho Viber QR kódu došlo k chybě. Prosím, zkuste to znovu.',
    wechatChannelDescription: 'Pro pokračování prostřednictvím WeChatu připojte svůj WeChat účet a budete upozorněni na odpověď. Pro začátek naskenujte tento QR kód pomocí aplikace WeChat.',
    wechatChannelDescriptionMobile: 'Pro pokračování prostřednictvím WeChatu připojte svůj WeChat účet a budete upozorněni na odpověď. Pro začátek si uložte tento QR kód a nahrajte ho do <a href="weixin://dl/scan">skeneru QR kódů</a>.',
    wechatQRCodeError: 'Při získávání vášho WeChat QR kódu došlo k chybě. Prosím, zkuste to znovu.',
    fileTooLargeError: 'Byl překročen maximální limit velikosti souboru ({size})',
    shareLocation: 'Sdílet polohu',
    uploadDocument: 'Nahrát dokument',
    uploadInvalidError: 'Neplatný soubor',
    fileTypeError: 'Nepodporovaný typ souboru',
    formErrorInvalidEmail: 'E-mail je neplatný',
    formErrorNoLongerThan: 'Musí obsahovat nejvýše ({characters}) znaků',
    formErrorNoShorterThan: 'Musí obsahovat nejméně ({characters}) znaků',
    formErrorUnknown: 'To nevypadá zcela správně',
    formFieldSelectPlaceholderFallback: 'Vyberte si...',
    uploadPhoto: 'Nahrát fotku',
    uploadVirusError: 'Byl zjištěn virus ve vašem souboru a byl odmítnut',
    shoutoutText: 'Zahájit chat',
    cardClickText: 'Vybraný'
};
export { Czech };
