/* eslint max-len:0 */
const Norwegian = {
    _language: 'norwegian',
    _language_short: 'no',
    agentWelkomMessage: 'Velkommen. Spør spørsmålene dine her.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Kunne ikke koble til. Du kan {retry}',
    couldNotConnectInner: 'prøve å koble til igjen nå',
    couldNotConnectWithRetry: 'Kunne ikke koble til. Vi vil fortsette å prøve, eller du kan {retry}.',
    couldNotConnectWithRetryInner: 'prøv nå',
    headerText: 'Hvordan kan vi hjelpe deg?',
    imageClickToReload: 'Klikk for å laste inn bildet på nytt.',
    imageClickToView: 'Klikk for å se bildet {size}.',
    imagePreviewNotAvailable: 'Forhåndsvisning er ikke tilgjengelig.',
    inputPlaceholder: 'Skriv her og trykk <enter> for å sende.',
    inputPlaceholderBlocked: 'Fullfør skjemaet nedenfor...',
    introAppText: 'Send meldingen din nedenfor eller fra din favorittkanal.',
    introductionText: 'Still spørsmålene dine her.',
    actionPaymentCompleted: 'Betalingen er mottatt.',
    actionPaymentError: 'Det oppstod en feil under behandlingen av betalingen. <br> Vennligst prøv igjen eller bruk et annet kort.',
    actionPostbackError: 'Det oppstod en feil under behandlingen av handlingen din. Vennligst prøv igjen.',
    clickToRetry: 'Meldingen ble ikke levert. Vennligst prøv igjen.',
    clickToRetryForm: 'Skjemaet ble ikke sendt. Klikk hvor som helst på skjemaet for å prøve igjen.',
    connectNotificationText: 'Vent på svar eller velg hvilken app du vil fortsette med.',
    connectNotificationSingleText: 'Vent på svar eller velg hvilken app du vil fortsette med.',
    connectNotificationSingleButtonText: 'Slå på varsler fra <name>',
    connectNotificationOthersText: 'andre',
    emailChangeAddress: 'Endre e-postadressen min',
    emailDescription: 'For å bli varslet via e-post om svar, oppgi e-postadressen din.',
    emailFieldLabel: 'Din e-post',
    emailFieldPlaceholder: 'Din e-postadresse',
    emailFormButton: 'Fortsett',
    fetchHistory: 'Hent mer',
    fetchingHistory: 'Henter historikk...',
    frontendEmailChannelDescription: 'Send oss en e-post, og vi vil svare deg så snart som mulig.',
    invalidFileError: 'Bare bilder støttes. Velg en fil med støttet format (jpg, jpeg, png, gif eller bmp).',
    lineChannelDescription: 'For å kommunisere med oss, skann QR-koden med LINE-appen.',
    locationNotSupported: 'Nettleseren din støtter ikke stedsbaserte tjenester eller de er deaktivert. Vennligst skriv inn posisjonen din manuelt.',
    locationSecurityRestriction: 'Denne nettsiden har ikke tilgang til posisjonen din. Vennligst skriv inn posisjonen din manuelt.',
    locationSendingFailed: 'Posisjonen kunne ikke sendes',
    locationServicesDenied: 'Denne nettsiden har ikke tilgang til posisjonen din. Tillat tilgang i innstillingene dine eller skriv inn posisjonen din manuelt.',
    messageError: 'Det oppstod en feil under sendingen av meldingen din. Vennligst prøv igjen.',
    messageIndicatorTitlePlural: '({count}) Nye meldinger',
    messageIndicatorTitleSingular: '({count}) Ny melding',
    messageRelativeTimeDay: 'For {value} dager siden',
    messageRelativeTimeHour: 'For {value} timer siden',
    messageRelativeTimeJustNow: 'Akkurat nå',
    messageRelativeTimeMinute: 'For {value} minutter siden',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'Sender...',
    messageDelivered: 'Levert',
    messengerChannelDescription: 'For å fortsette med Facebook Messenger, koble kontoen din til Facebook.',
    whatsappChannelDescriptionDesktop: 'Start eller fortsett samtalen din via WhatsApp. Klikk på lenken nedenfor.',
    whatsappChannelDescriptionMobile: 'Start eller fortsett samtalen din via WhatsApp. Klikk på lenken nedenfor.',
    whatsappLinkingError: 'Det oppstod en feil under koblingen. Vennligst prøv igjen.',
    notificationSettingsChannelsDescription: 'Du kan også chatte fra din favorittapp eller tjeneste.',
    notificationSettingsChannelsTitle: 'Andre apper',
    notificationSettingsConnected: 'Koblet til',
    notificationSettingsConnectedAs: 'Koblet til som {username}',
    prechatCaptureGreetingText: 'Hei 👋\nVi vil gjerne vite litt mer om deg for å komme i gang:',
    prechatCaptureNameLabel: 'Ditt navn',
    prechatCaptureNamePlaceholder: 'Skriv inn navnet ditt...',
    prechatCaptureEmailLabel: 'E-post',
    prechatCaptureEmailPlaceholder: 'name@company.com',
    prechatCaptureConfirmationText: 'Takk for det! Hvordan kan vi hjelpe deg?',
    prechatCaptureMailgunLinkingConfirmation: 'Du vil bli varslet her og via e-post på {email} når vi svarer deg. ',
    sendButtonText: 'Send',
    settingsHeaderText: 'Innstillinger',
    smsBadRequestError: 'Kunne ikke koble til dette nummeret. Prøv igjen eller bruk et annet nummer. ',
    smsCancel: 'Avbryt',
    smsChangeNumber: 'Endre nummeret mitt',
    smsChannelDescription: 'For å fortsette via SMS, koble til SMS-nummeret ditt.',
    smsChannelPendingDescription: 'Sjekk meldingene dine på {number} for å bekrefte telefonnummeret ditt.',
    smsContinue: 'Fortsett',
    smsInvalidNumberError: 'Telefonnummeret ditt er ikke gyldig. Prøv igjen.',
    smsLinkCancelled: 'Koblingen til {appUserNumber} ble avbrutt.',
    smsLinkPending: 'Venter',
    smsPingChannelError: 'Det oppstod en feil under sendingen av meldingen til nummeret ditt.',
    smsSendText: 'Send meg tekstmelding',
    smsStartTexting: 'Start SMS-samtalen',
    smsTooManyRequestsError: 'Forespørselen om å koble til dette nummeret ble nylig gjort. Prøv igjen om {minutes} minutter.',
    smsTooManyRequestsOneMinuteError: 'Forespørselen om å koble til dette nummeret ble nylig gjort. Prøv igjen om 1 minutt.',
    smsUnhandledError: 'Noe gikk galt. Prøv igjen.',
    tapToRetry: 'Meldingen ble ikke levert. Trykk enter for å sende på nytt.',
    tapToRetryForm: 'Skjemaet ble ikke sendt. Trykk på skjemaet for å prøve igjen.',
    telegramChannelDescription: 'For å fortsette, koble til kontoen din på Telegram.',
    unsupportedMessageType: 'Ikke støttet meldingstype.',
    unsupportedActionType: 'Ikke støttet handlingstype.',
    linkError: 'Det oppstod en feil under forsøket på å opprette en lenke for denne kanalen. Prøv igjen.',
    viberChannelDescription: 'For å fortsette, koble til Viber-kontoen din.',
    viberChannelDescriptionMobile: 'For å fortsette, koble til Viber-kontoen din og bli varslet om svaret. Installer Viber-appen og trykk på Koble til.',
    viberQRCodeError: 'Det oppstod en feil under henting av Viber QR-koden din. Prøv igjen.',
    wechatChannelDescription: 'For å fortsette, koble til WeChat-kontoen din og bli varslet om svaret. Skann denne QR-koden med WeChat-appen for å komme i gang.',
    wechatChannelDescriptionMobile: 'For å fortsette, koble til WeChat-kontoen din og bli varslet om svaret. Lagre denne QR-koden og last den opp i <a href="weixin://dl/scan">QR-kodeleseren</a>.',
    wechatQRCodeError: 'Det oppstod en feil under henting av WeChat QR-koden din. Prøv igjen.',
    fileTooLargeError: 'Filen er for stor ({size})',
    shareLocation: 'Del plasseringen',
    uploadDocument: 'Last opp dokument',
    uploadInvalidError: 'Ugyldig fil',
    fileTypeError: 'Ikke støttet filtype',
    formErrorInvalidEmail: 'E-postadressen er ugyldig',
    formErrorNoLongerThan: 'Må ikke være lengre enn ({characters}) tegn',
    formErrorNoShorterThan: 'Må være minst ({characters}) tegn',
    formErrorUnknown: 'Noe ser ikke helt riktig ut',
    formFieldSelectPlaceholderFallback: 'Velg...',
    uploadPhoto: 'Last opp bilde',
    uploadVirusError: 'Det ble oppdaget et virus i filen din, og den ble avvist',
    shoutoutText: 'Start samtalen',
    cardClickText: 'Valgt'
};
export { Norwegian };
