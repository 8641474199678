/* eslint max-len:0 */
const Romanian = {
    _language: 'romanian',
    _language_short: 'ro',
    agentWelkomMessage: 'Bun venit. Scrieți întrebarea dumneavoastră aici.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Nu s-a putut realiza conexiunea. Puteți {reîncerca}',
    couldNotConnectInner: 'Reîncercați conectarea acum',
    couldNotConnectWithRetry: 'Nu s-a putut realiza conexiunea. Vom încerca din nou, sau puteți {reîncerca}.',
    couldNotConnectWithRetryInner: 'Încercați acum',
    headerText: 'Cum vă putem ajuta?',
    imageClickToReload: 'Faceți clic pentru a reîncărca imaginea.',
    imageClickToView: 'Faceți clic pentru a vizualiza imaginea {mărime}.',
    imagePreviewNotAvailable: 'Previzualizarea nu este disponibilă.',
    inputPlaceholder: 'Tastați aici, apăsați <enter> pentru a trimite.',
    inputPlaceholderBlocked: 'Completați formularul de mai sus...',
    introAppText: 'Scrieți mesajul dvs. mai jos sau de pe canalul dvs. preferat.',
    introductionText: 'Scrieți întrebarea dumneavoastră aici.',
    actionPaymentCompleted: 'Plată primită.',
    actionPaymentError: 'A intervenit o eroare la procesarea plății. <br> Vă rugăm să încercați din nou sau să utilizați o altă carte.',
    actionPostbackError: 'A intervenit o eroare la procesarea acțiunii dvs. Vă rugăm să încercați din nou.',
    clickToRetry: 'Mesajul nu a fost livrat. Vă rugăm să încercați din nou.',
    clickToRetryForm: 'Formularul nu a fost trimis. Faceți clic oriunde în formular pentru a încerca din nou.',
    connectNotificationText: 'Așteptați răspunsul sau alegeți în ce aplicație doriți să continuați.',
    connectNotificationSingleText: 'Așteptați răspunsul sau alegeți în ce aplicație doriți să continuați.',
    connectNotificationSingleButtonText: 'Activează notificările pentru <nume>',
    connectNotificationOthersText: 'Altele',
    emailChangeAddress: 'Schimbă adresa mea de email',
    emailDescription: 'Pentru a fi notificat prin e-mail când primiți un răspuns, introduceți adresa dvs. de e-mail.',
    emailFieldLabel: 'Adresa dvs. de e-mail',
    emailFieldPlaceholder: 'Adresa dvs. de e-mail',
    emailFormButton: 'Continuă',
    fetchHistory: 'Încărcați mai mult',
    fetchingHistory: 'Se preia istoricul...',
    frontendEmailChannelDescription: 'Trimiteți-ne un e-mail și vă vom răspunde cât mai curând posibil.',
    invalidFileError: 'Sunt acceptate doar imagini. Alegeți un fișier cu un format acceptat (jpg, jpeg, png, gif sau bmp).',
    lineChannelDescription: 'Scanați codul QR pentru a ne contacta prin LINE.',
    locationNotSupported: 'Browser-ul dvs. nu suportă serviciile de localizare sau au fost dezactivate. Vă rugăm să tastați locația dvs.',
    locationSecurityRestriction: 'Acest site web nu poate accesa locația dvs. Vă rugăm să tastați locația dvs. în schimb.',
    locationSendingFailed: 'Nu s-a putut trimite locația',
    locationServicesDenied: 'Acest site web nu poate accesa locația dvs. Permiteți accesul în setările dvs. sau tastați locația dvs. în schimb.',
    messageError: 'A intervenit o eroare la trimiterea mesajului dvs. Vă rugăm să încercați din nou.',
    messageIndicatorTitlePlural: '({count}) Mesaje noi',
    messageIndicatorTitleSingular: '({count}) Mesaj nou',
    messageRelativeTimeDay: '{value}zile în urmă',
    messageRelativeTimeHour: '{value}ore în urmă',
    messageRelativeTimeJustNow: 'Chiar acum',
    messageRelativeTimeMinute: '{value}minute în urmă',
    messageTimestampFormat: 'h:mm A',
    messageSending: 'Se trimite...',
    messageDelivered: 'Livrat',
    messengerChannelDescription: 'Conectați-vă contul de Facebook pentru a continua cu Facebook Messenger.',
    whatsappChannelDescriptionDesktop: 'Începeți sau continuați conversația cu WhatsApp? Faceți clic pe link-ul de mai jos.',
    whatsappChannelDescriptionMobile: 'Începeți sau continuați conversația prin WhatsApp? Faceți clic pe link-ul de mai jos.',
    whatsappLinkingError: 'A intervenit o eroare la conectare. Vă rugăm să încercați din nou.',
    notificationSettingsChannelsDescription: 'De asemenea, puteți discuta cu noi de pe aplicația sau serviciul dvs. preferat.',
    notificationSettingsChannelsTitle: 'Alte aplicații',
    notificationSettingsConnected: 'Conectat',
    notificationSettingsConnectedAs: 'Conectat ca {username}',
    prechatCaptureGreetingText: 'Salut 👋\nPentru a începe, dorim să aflăm puțin mai multe despre dvs.:',
    prechatCaptureNameLabel: 'Numele dvs.',
    prechatCaptureNamePlaceholder: 'Tastați numele dvs...',
    prechatCaptureEmailLabel: 'E-mail',
    prechatCaptureEmailPlaceholder: 'nume@companie.com',
    prechatCaptureConfirmationText: 'Mulțumim! Cu ce vă putem ajuta?',
    prechatCaptureMailgunLinkingConfirmation: 'Veti fi notificat aici și prin email la {email} odată ce răspundem. ',
    sendButtonText: 'Trimiteți',
    settingsHeaderText: 'Setări',
    smsBadRequestError: 'Nu am putut conecta acest număr. Încercați din nou sau utilizați un alt număr. ',
    smsCancel: 'Anulați',
    smsChangeNumber: 'Schimbă numărul meu',
    smsChannelDescription: 'Conectați-vă numărul de telefon pentru a continua prin SMS.',
    smsChannelPendingDescription: 'Verificați mesajele dvs. la {number} pentru a confirma numărul dvs. de telefon.',
    smsContinue: 'Continuați',
    smsInvalidNumberError: 'Numărul dvs. de telefon nu este valid. Vă rugăm să încercați din nou.',
    smsLinkCancelled: 'Conexiunea către {appUserNumber} a fost anulată.',
    smsLinkPending: 'În așteptare',
    smsPingChannelError: 'A intervenit o eroare la trimiterea unui mesaj către numărul dvs.',
    smsSendText: 'Trimiteți-mi un mesaj',
    smsStartTexting: 'Începeți să trimiteți mesaje',
    smsTooManyRequestsError: 'Recent a fost solicitată o conexiune pentru acel număr. Vă rugăm să încercați din nou în {minutes} minute.',
    smsTooManyRequestsOneMinuteError: 'Recent a fost solicitată o conexiune pentru acel număr. Vă rugăm să încercați din nou în 1 minut.',
    smsUnhandledError: 'Ceva a mers greșit. Vă rugăm să încercați din nou.',
    tapToRetry: 'Mesajul nu a fost livrat. Apăsați enter pentru a încerca din nou.',
    tapToRetryForm: 'Formularul nu a fost trimis. Dați click oriunde pe formular pentru a încerca din nou.',
    telegramChannelDescription: 'Conectați-vă contul de Telegram pentru a continua prin Telegram',
    unsupportedMessageType: 'Tip de mesaj neacceptat.',
    unsupportedActionType: 'Tip de acțiune neacceptată.',
    linkError: 'A intervenit o eroare la generarea unui link pentru acest canal. Vă rugăm să încercați din nou.',
    viberChannelDescription: 'Conectați-vă contul de Viber pentru a continua prin Viber',
    viberChannelDescriptionMobile: 'Conectați-vă contul de Viber pentru a primi notificări atunci când primiți un răspuns și pentru a continua conversația pe Viber. Pentru a începe, instalați aplicația Viber și apăsați Conectare.',
    viberQRCodeError: 'A intervenit o eroare la preluarea codului QR Viber. Vă rugăm să încercați din nou.',
    wechatChannelDescription: 'Conectați-vă contul de WeChat pentru a primi notificări atunci când primiți un răspuns și pentru a continua conversația pe WeChat. Pentru a începe, scanați acest cod QR folosind aplicația WeChat.',
    wechatChannelDescriptionMobile: 'Conectați-vă contul de WeChat pentru a primi notificări atunci când primiți un răspuns și pentru a continua conversația pe WeChat. Pentru a începe, salvați această imagine cu codul QR și încărcați-o în <a href="weixin://dl/scan">scanerul de cod QR</a>.',
    wechatQRCodeError: 'A intervenit o eroare la preluarea codului QR WeChat. Vă rugăm să încercați din nou.',
    fileTooLargeError: 'Dimensiunea fișierului depășește limita maximă ({size})',
    shareLocation: 'Partajează locația',
    uploadDocument: 'Încărcați documentul',
    uploadInvalidError: 'Fișier invalid',
    fileTypeError: 'Tip de fișier neacceptat',
    formErrorInvalidEmail: 'E-mailul nu este valid',
    formErrorNoLongerThan: 'Trebuie să conțină cel mult ({characters}) caractere',
    formErrorNoShorterThan: 'Trebuie să conțină cel puțin ({characters}) caractere',
    formErrorUnknown: 'Ceva nu este în regulă',
    formFieldSelectPlaceholderFallback: 'Alegeți unul...',
    uploadPhoto: 'Încărcați fotografia',
    uploadVirusError: 'S-a detectat un virus în fișierul dvs. și a fost respins',
    shoutoutText: 'Începeți o conversație',
    cardClickText: 'Selectat'
};
export { Romanian };
