/* eslint max-len:0 */
const Polish = {
    _language: 'polish',
    _language_short: 'pl',
    agentWelkomMessage: 'Witaj. Zadaj pytanie tutaj.',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: 'Nie można połączyć. Możesz {retry}',
    couldNotConnectInner: 'ponów próbę połączenia teraz',
    couldNotConnectWithRetry: 'Nie można połączyć. Będziemy kontynuować próby, lub możesz {retry}.',
    couldNotConnectWithRetryInner: 'spróbuj teraz',
    headerText: 'Jak możemy pomóc?',
    imageClickToReload: 'Kliknij, aby odświeżyć obraz.',
    imageClickToView: 'Kliknij, aby wyświetlić obraz {size}.',
    imagePreviewNotAvailable: 'Podgląd niedostępny.',
    inputPlaceholder: 'Wpisz treść tutaj naciśnij <Enter> by przesłać wiadomość',
    inputPlaceholderBlocked: 'Wypełnij formularz powyżej...',
    introAppText: 'Wyślij swoją wiadomość poniżej lub za pomocą ulubionej aplikacji.',
    introductionText: 'Zadaj pytanie tutaj.',
    actionPaymentCompleted: 'Otrzymano płatność.',
    actionPaymentError: 'Wystąpił błąd podczas przetwarzania płatności. <br> Spróbuj ponownie lub użyj innej karty.',
    actionPostbackError: 'Wystąpił błąd podczas przetwarzania Twojej akcji. Spróbuj ponownie.',
    clickToRetry: 'Wiadomość nie dostarczona. Spróbuj ponownie.',
    clickToRetryForm: 'Formularz nie został przesłany. Kliknij w dowolne miejsce w formularzu, aby spróbować ponownie.',
    connectNotificationText: 'Oczekuj odpowiedzi lub wybierz, w jakiej aplikacji chcesz kontynuować.',
    connectNotificationSingleText: 'Oczekuj odpowiedzi lub wybierz, w jakiej aplikacji chcesz kontynuować.',
    connectNotificationSingleButtonText: 'Włącz powiadomienia <name>',
    connectNotificationOthersText: 'inne',
    emailChangeAddress: 'Zmień mój adres e-mail',
    emailDescription: 'Aby otrzymywać powiadomienia e-mailem, wpisz swój adres e-mail.',
    emailFieldLabel: 'Twój adres e-mail',
    emailFieldPlaceholder: 'Twój adres e-mail',
    emailFormButton: 'Kontynuuj',
    fetchHistory: 'Załaduj więcej',
    fetchingHistory: 'Pobieranie historii...',
    frontendEmailChannelDescription: 'Wyślij do nas e-maila, a odpowiemy tak szybko, jak to możliwe.',
    invalidFileError: 'Obsługiwane są tylko obrazy. Wybierz plik o obsługiwanych rozszerzeniach (jpg, jpeg, png, gif lub bmp).',
    lineChannelDescription: 'Zeskanuj kod QR, aby rozmawiać z nami za pośrednictwem LINE.',
    locationNotSupported: 'Twoja przeglądarka nie obsługuje usług lokalizacji lub zostały one wyłączone. Wpisz swoją lokalizację.',
    locationSecurityRestriction: 'Ta strona internetowa nie może uzyskać dostępu do Twojej lokalizacji. Wpisz swoją lokalizację.',
    locationSendingFailed: 'Nie można wysłać lokalizacji',
    locationServicesDenied: 'Ta strona internetowa nie może uzyskać dostępu do Twojej lokalizacji. Zezwól na dostęp w ustawieniach lub wpisz swoją lokalizację.',
    messageError: 'Wystąpił błąd podczas wysyłania wiadomości. Spróbuj ponownie.',
    messageIndicatorTitlePlural: '({count}) Nowe wiadomości',
    messageIndicatorTitleSingular: '({count}) Nowa wiadomość',
    messageRelativeTimeDay: '{value}d temu',
    messageRelativeTimeHour: '{value}h temu',
    messageRelativeTimeJustNow: 'Przed chwilą',
    messageRelativeTimeMinute: '{value}m temu',
    messageTimestampFormat: 'H:mm',
    messageSending: 'Wysyłanie...',
    messageDelivered: 'Dostarczono',
    messengerChannelDescription: 'Połącz swoje konto na Facebooku, aby kontynuować za pośrednictwem Messengera.',
    whatsappChannelDescriptionDesktop: 'Rozpocznij lub kontynuuj rozmowę za pomocą WhatsApp? Kliknij poniższy link.',
    whatsappChannelDescriptionMobile: 'Rozpocznij lub kontynuuj rozmowę za pomocą WhatsApp? Kliknij poniższy link.',
    whatsappLinkingError: 'Wystąpił problem podczas łączenia. Spróbuj ponownie.',
    notificationSettingsChannelsDescription: 'Możesz również rozmawiać z nami za pośrednictwem swojej ulubionej aplikacji lub usługi.',
    notificationSettingsChannelsTitle: 'Inne aplikacje',
    notificationSettingsConnected: 'Połączono',
    notificationSettingsConnectedAs: 'Połączono jako {username}',
    prechatCaptureGreetingText: 'Cześć 👋\nChcielibyśmy dowiedzieć się nieco więcej o Tobie, aby rozpocząć:',
    prechatCaptureNameLabel: 'Twoje imię',
    prechatCaptureNamePlaceholder: 'Wpisz swoje imię...',
    prechatCaptureEmailLabel: 'E-mail',
    prechatCaptureEmailPlaceholder: 'nazwa@firma.com',
    prechatCaptureConfirmationText: 'Dzięki za to! Jak możemy Ci pomóc?',
    prechatCaptureMailgunLinkingConfirmation: 'Otrzymasz tutaj i na adres {email} powiadomienie, gdy odpowiemy.',
    sendButtonText: 'Wyślij',
    settingsHeaderText: 'Ustawienia',
    smsBadRequestError: 'Nie można połączyć się z tym numerem. Spróbuj ponownie lub użyj innego numeru.',
    smsCancel: 'Anuluj',
    smsChangeNumber: 'Zmień mój numer',
    smsChannelDescription: 'Połącz swój numer telefonu SMS, aby kontynuować za pośrednictwem SMS.',
    smsChannelPendingDescription: 'Wprowadź swój numer telefonu, aby kontynuować za pośrednictwem SMS.',
    smsContinue: 'Kontynuuj',
    smsInvalidNumberError: 'Twój numer telefonu jest nieprawidłowy. Spróbuj ponownie.',
    smsLinkCancelled: 'Połączenie z numerem {appUserNumber} zostało anulowane.',
    smsLinkPending: 'Oczekujące',
    smsPingChannelError: 'Wystąpił błąd podczas wysyłania wiadomości na Twój numer.',
    smsSendText: 'Wyślij mi SMS',
    smsStartTexting: 'Rozpocznij pisanie',
    smsTooManyRequestsError: 'W ostatnim czasie wystąpiła prośba o połączenie dla tego numeru. Spróbuj ponownie za {minutes} minut.',
    smsTooManyRequestsOneMinuteError: 'W ostatnim czasie wystąpiła prośba o połączenie dla tego numeru. Spróbuj ponownie za 1 minutę.',
    smsUnhandledError: 'Wystąpił błąd. Spróbuj ponownie.',
    tapToRetry: 'Wiadomość nie została dostarczona. Naciśnij enter, aby spróbować ponownie.',
    tapToRetryForm: 'Formularz nie został przesłany. Dotknij dowolne miejsce w formularzu, aby spróbować ponownie.',
    telegramChannelDescription: 'Połącz swoje konto Telegram, aby kontynuować za pośrednictwem Telegrama.',
    unsupportedMessageType: 'Nieobsługiwany rodzaj wiadomości.',
    unsupportedActionType: 'Nieobsługiwany rodzaj działania.',
    linkError: 'Wystąpił błąd podczas generowania linku dla tego kanału. Spróbuj ponownie.',
    viberChannelDescription: 'Połącz swoje konto Viber, aby kontynuować za pośrednictwem Viber',
    viberChannelDescriptionMobile: 'Połącz swoje konto Viber, aby otrzymywać powiadomienia o odpowiedziach i kontynuować rozmowę w Viberze. Aby rozpocząć, zainstaluj aplikację Viber i dotknij "Połącz".',
    viberQRCodeError: 'Wystąpił błąd podczas pobierania kodu QR Viber. Spróbuj ponownie.',
    wechatChannelDescription: 'Połącz swoje konto WeChat, aby otrzymywać powiadomienia o odpowiedziach i kontynuować rozmowę w WeChat. Aby rozpocząć, zeskanuj ten kod QR za pomocą aplikacji WeChat.',
    wechatChannelDescriptionMobile: 'Połącz swoje konto WeChat, aby otrzymywać powiadomienia o odpowiedziach i kontynuować rozmowę w WeChat. Aby rozpocząć, zapisz ten obraz kodu QR i wyślij go za pomocą <a href="weixin://dl/scan">skanera kodów QR</a>.',
    wechatQRCodeError: 'Wystąpił błąd podczas pobierania kodu QR WeChat. Spróbuj ponownie.',
    fileTooLargeError: 'Przekroczono maksymalny rozmiar pliku ({size})',
    shareLocation: 'Udostępnij lokalizację',
    uploadDocument: 'Prześlij dokument',
    uploadInvalidError: 'Nieprawidłowy plik',
    fileTypeError: 'Nieobsługiwany typ pliku',
    formErrorInvalidEmail: 'Nieprawidłowy adres email',
    formErrorNoLongerThan: 'Musi zawierać maksymalnie ({characters}) znaków',
    formErrorNoShorterThan: 'Musi zawierać co najmniej ({characters}) znaków',
    formErrorUnknown: 'To wygląda nieprawidłowo',
    formFieldSelectPlaceholderFallback: 'Wybierz jedno... ',
    uploadPhoto: 'Prześlij zdjęcie',
    uploadVirusError: 'Wykryto wirusa w Twoim pliku i został odrzucony',
    shoutoutText: 'Rozpocznij czat',
    cardClickText: 'Wybrany'
};
export { Polish };
