/* eslint max-len:0 */
const Japanese = {
    _language: 'japanese',
    _language_short: 'jp',
    agentWelkomMessage: 'ようこそ。ここで質問してください。',
    conversationTimestampHeaderFormat: 'HH:mm',
    couldNotConnect: '接続できませんでした。{retry}できます',
    couldNotConnectInner: '再接続を試みる',
    couldNotConnectWithRetry: '接続できませんでした。再試行しますか、それとも{retry}。',
    couldNotConnectWithRetryInner: '今試してください',
    headerText: 'どのようにお手伝いできますか？',
    imageClickToReload: '画像を再読み込みするにはクリックしてください。',
    imageClickToView: '{size}画像を表示するにはクリックしてください。',
    imagePreviewNotAvailable: 'プレビューは利用できません。',
    inputPlaceholder: '入力後、送信してください.',
    inputPlaceholderBlocked: '上記のフォームを完了してください...',
    introAppText: '以下からメッセージを送信するか、お気に入りのチャネルから送信してください。',
    introductionText: 'ここで質問してください。',
    actionPaymentCompleted: '支払いが完了しました。',
    actionPaymentError: '支払い処理中にエラーが発生しました。<br>もう一度試してくださいか、別のカードを使用してください。',
    actionPostbackError: 'アクションの処理中にエラーが発生しました。もう一度やり直してください。',
    clickToRetry: 'メッセージの配信に失敗しました。もう一度試してください。',
    clickToRetryForm: 'フォームの送信に失敗しました。フォームのどこかをクリックして再試行してください。',
    connectNotificationText: '返信を待つか、続行するアプリを選択してください。',
    connectNotificationSingleText: '返信を待つか、続行するアプリを選択してください。',
    connectNotificationSingleButtonText: '<name>の通知をオンにする',
    connectNotificationOthersText: 'その他',
    emailChangeAddress: 'メールアドレスを変更する',
    emailDescription: '返信があるとメールで通知される場合は、メールアドレスを入力してください。',
    emailFieldLabel: 'あなたのメールアドレス',
    emailFieldPlaceholder: 'あなたのメールアドレス',
    emailFormButton: '続行',
    fetchHistory: 'さらに読み込む',
    fetchingHistory: '履歴を取得中...',
    frontendEmailChannelDescription: 'メールを送信し、できるだけ早くご連絡いたします。',
    invalidFileError: '画像のみサポートされています。サポートされている拡張子のファイルを選択してください（jpg、jpeg、png、gif、またはbmp）。 ',
    lineChannelDescription: 'QRコードをスキャンしてLINEを介して話しかけてください。',
    locationNotSupported: 'お使いのブラウザは位置サービスをサポートしていないか、無効になっています。代わりに場所を入力してください。',
    locationSecurityRestriction: 'このウェブサイトは位置情報にアクセスできません。代わりに場所を入力してください。',
    locationSendingFailed: '場所を送信できませんでした',
    locationServicesDenied: 'このウェブサイトは位置情報にアクセスできません。設定でアクセスを許可するか、代わりに場所を入力してください。',
    messageError: 'メッセージの送信中にエラーが発生しました。もう一度試してください。',
    messageIndicatorTitlePlural: '({count}) 新しいメッセージ',
    messageIndicatorTitleSingular: '({count}) 新しいメッセージ',
    messageRelativeTimeDay: '{value}日前',
    messageRelativeTimeHour: '{value}時間前',
    messageRelativeTimeJustNow: 'たった今',
    messageRelativeTimeMinute: '{value}分前',
    messageTimestampFormat: 'h:mm A',
    messageSending: '送信中...',
    messageDelivered: '配信完了',
    messengerChannelDescription: 'Facebookアカウントを接続して、Facebook Messenger経由で続行してください。',
    whatsappChannelDescriptionDesktop: 'WhatsAppを介して会話を開始または続行しますか？以下のリンクをクリックしてください。',
    whatsappChannelDescriptionMobile: 'WhatsAppを介して会話を開始または続行しますか？以下のリンクをクリックしてください。',
    whatsappLinkingError: '接続中に問題が発生しました。もう一度試してください。',
    notificationSettingsChannelsDescription: 'お気に入りのアプリまたはサービスからも話しかけることができます。',
    notificationSettingsChannelsTitle: 'その他のアプリ',
    notificationSettingsConnected: '接続されました',
    notificationSettingsConnectedAs: '{username}として接続されました',
    prechatCaptureGreetingText: 'こんにちは👋\nまず始めに、あなたについて少し詳しく知りたいです：',
    prechatCaptureNameLabel: 'あなたの名前',
    prechatCaptureNamePlaceholder: '名前を入力してください...',
    prechatCaptureEmailLabel: 'メール',
    prechatCaptureEmailPlaceholder: 'name@company.com',
    prechatCaptureConfirmationText: 'ありがとうございます！何をお手伝いできますか？',
    prechatCaptureMailgunLinkingConfirmation: '返信があると、{email}にこことメールで通知されます。',
    sendButtonText: '送信',
    settingsHeaderText: '設定',
    smsBadRequestError: 'この番号に接続できませんでした。もう一度試してくださいか、別の番号を使用してください。',
    smsCancel: 'キャンセル',
    smsChangeNumber: '番号を変更する',
    smsChannelDescription: 'SMS番号を接続して続行してください。',
    smsChannelPendingDescription: '電話番号を確認するために、{number}でメッセージを確認してください。',
    smsContinue: '続行',
    smsInvalidNumberError: '電話番号が無効です。もう一度試してください。',
    smsLinkCancelled: '{appUserNumber}へのリンクがキャンセルされました。',
    smsLinkPending: '保留中',
    smsPingChannelError: 'あなたの番号にメッセージを送信する際にエラーが発生しました。',
    smsSendText: 'テキストを送信してください',
    smsStartTexting: 'テキストを開始',
    smsTooManyRequestsError: 'その番号の接続が最近要求されました。{minutes}分後にもう一度試してください。',
    smsTooManyRequestsOneMinuteError: 'その番号の接続が最近要求されました。1分後にもう一度試してください。',
    smsUnhandledError: '何か問題が発生しました。もう一度試してください。',
    tapToRetry: 'メッセージの配信に失敗しました。再試行するにはEnterキーを押してください。',
    tapToRetryForm: 'フォームの送信に失敗しました。フォームのどこかをタップして再試行してください。',
    telegramChannelDescription: 'Telegramアカウントを接続して続行してください',
    unsupportedMessageType: 'サポートされていないメッセージタイプ。',
    unsupportedActionType: 'サポートされていないアクションタイプ。',
    linkError: 'このチャンネルのリンクを生成しようとする際にエラーが発生しました。もう一度試してください。',
    viberChannelDescription: 'Viberアカウントを接続して続行してください。',
    viberChannelDescriptionMobile: '返信があると通知され、Viberで会話を続けることができます。始めるには、Viberアプリをインストールして、接続をタップしてください。',
    viberQRCodeError: 'Viber QRコードの取得中にエラーが発生しました。もう一度試してください。',
    wechatChannelDescription: 'WeChatアカウントを接続して返信を通知し、WeChatで会話を続けてください。始めるには、このQRコードをWeChatアプリでスキャンしてください。',
    wechatChannelDescriptionMobile: 'WeChatアカウントを接続して返信を通知し、WeChatで会話を続けてください。始めるには、このQRコードの画像を保存し、<a href="weixin://dl/scan">QRコードスキャナー</a>にアップロードしてください。',
    wechatQRCodeError: 'WeChat QRコードの取得中にエラーが発生しました。もう一度試してください。',
    fileTooLargeError: 'ファイルサイズの上限を超えました（{size}）',
    shareLocation: '位置を共有',
    uploadDocument: 'ドキュメントをアップロード',
    uploadInvalidError: '無効なファイル',
    fileTypeError: 'サポートされていないファイルタイプ',
    formErrorInvalidEmail: 'メールが無効です',
    formErrorNoLongerThan: '（{characters}）文字を超えてはいけません',
    formErrorNoShorterThan: '（{characters}）文字以上である必要があります',
    formErrorUnknown: 'これはちょっと違うようです',
    formFieldSelectPlaceholderFallback: '選択してください...',
    uploadPhoto: '写真をアップロード',
    uploadVirusError: 'ファイルにウイルスが検出されましたので、拒否されました',
    shoutoutText: 'チャットを開始',
    cardClickText: '選択済み'
};
export { Japanese };
